import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PageLayout from '../../layout/PageLayout';
import { SocialGraph } from '../../basics';
import Bio from './Bio';

export function PureIndexScreen({
  data: {
    allContentfulPages: {
      nodes: [pageData],
    },
  },
  ...props
}) {
  const {
    title,
    body: { json: summary },
    description: { description },
    action,
    ...restOfPage
  } = pageData;

  const bioResume = {
    title,
    summary,
    action,
  };
  return (
    <PageLayout {...props} {...restOfPage}>
      <SocialGraph title={title} desc={description} />
      <main>
        <Bio {...bioResume} />
      </main>
    </PageLayout>
  );
}

export default function IndexScreen(props) {
  const data = useStaticQuery(graphql`
    query IndexScreenQuery {
      allContentfulPages(filter: { slug: { eq: "home" } }) {
        nodes {
          body {
            json
          }
          id
          slug
          title
          tags
          description {
            description
          }
          action
        }
      }
    }
  `);
  return <PureIndexScreen data={data} {...props} />;
}
