import { Button } from '@storybook/design-system';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import styled from 'styled-components';
import themeDefaultProps from '../../../themes/dark';

export const BioWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.typography.type.primary};
  font-weight: ${props => props.theme.typography.weight.black};
  font-size: ${props => props.theme.typography.size.m3}px;
  color: ${props => props.theme.color.primary};
  line-height: 32px;
  margin-bottom: 0.5rem;
  padding-left: ${props => props.theme.spacing.padding.medium}px;
  padding-right: ${props => props.theme.spacing.padding.medium}px;
  @media (min-width: ${props => props.theme.breakpoint * 1}px) {
    font-size: ${props => props.theme.typography.size.l3}px;
    line-height: 52px;
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoint * 2}px) {
    font-size: 56px;
    line-height: 60px;
    margin-bottom: 0.75rem;
  }
`;
Title.defaultProps = {
  theme: themeDefaultProps,
};
export const HeadLine = styled.h2`
  font-family: ${props => props.theme.typography.type.primary};
  font-weight: ${props => props.theme.typography.weight.black};
  font-size: ${props => props.theme.typography.size.m1}px;
  color: ${props => props.theme.color.primary};
  line-height: 32px;
  margin-bottom: 0.5rem;
  padding-left: ${props => props.theme.spacing.padding.medium}px;
  padding-right: ${props => props.theme.spacing.padding.medium}px;
  @media (min-width: ${props => props.theme.breakpoint * 1}px) {
    font-size: ${props => props.theme.typography.size.l3}px;
    line-height: 52px;
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoint * 2}px) {
    font-size: ${props => props.theme.typography.size.m1}px;
    line-height: ${props => props.theme.typography.lineHeight.m1}px;
    margin-bottom: 0.75rem;
  }
`;

HeadLine.defaultProps = {
  theme: themeDefaultProps,
};

export const Summary = styled.article`
  font-family: ${props => props.theme.typography.type.primary};
  font-weight: ${props => props.theme.typography.weight.normal};
  font-size: ${props => props.theme.typography.size.m1}px;
  color: ${props => props.theme.color.primary};
  line-height: 32px;
  margin-bottom: 0.5rem;
  text-align: justify;
  padding: ${props => props.theme.spacing.padding.medium}px;
  h3 {
    margin: 20px 0;
  }
  p {
    margin: 20px 0;
  }
  ul li {
    list-style-type: none;
  }
  @media (min-width: ${props => props.theme.breakpoint * 1}px) {
    font-size: ${props => props.theme.typography.size.l3}px;
    line-height: 52px;
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoint * 2}px) {
    font-size: ${props => props.theme.typography.size.m1}px;
    line-height: ${props => props.theme.typography.lineHeight.m1}px;
    margin-bottom: 0.75rem;
  }
`;
Summary.defaultProps = {
  theme: themeDefaultProps,
};

export const ActionsWrapper = styled.div`
  padding-left: ${props => props.theme.spacing.padding.medium}px;
  padding-right: ${props => props.theme.spacing.padding.medium}px;
`;
ActionsWrapper.defaultProps = {
  theme: themeDefaultProps,
};

export const ActionsButton = styled(Button)`
  font-family: ${props => props.theme.typography.type.secondary};
  border-radius: 0.5em;
  :hover {
    cursor: pointer;
  }
`;
ActionsButton.defaultProps = {
  theme: themeDefaultProps,
};

export default function Bio({ title, summary, action }) {
  return (
    <BioWrapper>
      <Title>{title}</Title>
      <Summary>{documentToReactComponents(summary)}</Summary>
      <ActionsWrapper>
        <ActionsButton
          isLink
          target="_blank"
          href="https://www.linkedin.com/in/leonarpv/"
        >
          {action}
        </ActionsButton>
      </ActionsWrapper>
    </BioWrapper>
  );
}
